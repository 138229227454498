import * as React from 'react'
import { Link } from 'gatsby'

import Page from '../components/Page'
import IndexLayout from '../layouts'
import { Section } from '@intalex/gatsby-theme-base';
import { ButtonPrimary } from "../common/styledComponents";

const NotFoundPage = () => {
  return (
    <IndexLayout>
      <Page>
        <Section color="darkest" padding={8}>
          <h1 style={{ textAlign: "center", color: "#fff" }}>Page not found</h1>
          </Section>
        <Section padding={10}>
          <p style={{textAlign: "center"}}>
            Sorry looks like we couldn't find the page you were looking for.
          </p>
          <p style={{ textAlign: "center" }}>
            <ButtonPrimary
              to="/"
            >
              Go Back
            </ButtonPrimary>
          </p>
        </Section>
      </Page>
    </IndexLayout>
  )
}

export default NotFoundPage
